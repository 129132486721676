import React from "react";
import { SocialIcons } from "../social-icons";
import { DownloadBtns } from "./downloadBtns";

export const Footer = (props: any) => {
  return (
    <div className="flex flex-col md:flex-row flex-wrap z-10 ">
      {/* home style */}
      {/* button */}
      <DownloadBtns />
      <div className="md:flex-1" />
      {/* social buttons */}
      <SocialIcons />
    </div>
  );
};
