import React from "react";
import { Container } from "src/components";

export const OurPartners = (props: any) => {
  return (
    <Container>
      <div className="pt-[20px]">
        {/* title */}
        <p className="text-3xl md:text-7xl font-black tracking-widest gradient-txt uppercase">
          Our Partners
        </p>
      </div>

      <div className="pt-[20px] text-center">
        <img
          src="/assets/partner_1.png"
          className="!hidden lg:!block w-full object-cover"
          alt="partners"
        />
        <img
          src="/assets/partner_2.png"
          className="!block lg:!hidden w-full object-cover"
          alt="partners"
        />
      </div>
    </Container>
  );
};
