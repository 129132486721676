const productAndServiceInstruction = [
  {
    title: "NFT Membership Card",
    context:
      "NFT Membership includes a unique, tradable NFT that serves as a membership card, providing customers with ownership and proof of their benefits and loyalty rewards. Our AT Birds, powered by OpenAI, allows customers to interact with their electronic pet and create engagement through gamification.",
  },
  {
    title: "Gamification features",
    context:
      'Such as "play to earn" and "spend to earn", similar to tokens, that increase user engagement and incentivize loyalty.',
  },
  {
    title: "Metaverse Solutions",
    context:
      "We offer services to build a metaverse for our merchant partners and instil a metaverse shopping experience for their customers as well as augmenting their business activities into immersive web 3.0 using AR and 3D navigation.",
  },
  {
    title: "eVoucher",
    context:
      "Our eVoucher system offers a definitive expiry date and can be written in the smart contract, providing customers with a clear understanding of the validity of their rewards.",
  },
  {
    title: " Web 3.0 Wallet Solution",
    context:
      "Our Wallet solution can be upgraded to a Web 3.0 infrastructure and incorporated with Decentralized Identifiers (DID) for authentication, providing customers with secure and transparent access to their loyalty rewards.",
  },
  {
    title: "Loyalty Points",
    context:
      "Our loyalty exchange allows customers to convert and interchange loyalty points across different merchants, NGO and for CSR purposes, providing flexibility and increasing the value of their rewards.",
  },
  {
    title: "Ecosystem with over 50,000 merchants locations in GBA",
    context:
      "Our strong merchant network leverages a unique business model supported by technology, resulting in a mutually beneficial environment for all parties involved.",
  },
];

export { productAndServiceInstruction };
