import React from "react";
import { history } from "src/stores";

export const Header = (props: any) => {
  const { handleMenu } = props;

  return (
    <div className="flex flex-row items-center">
      {/* Logo */}
      <img
        src="/assets/logo.png"
        alt="logo"
        className="cursor-pointer h-[40px]"
        onClick={() => history.push("/")}
      />

      {/* Dropdown Menu Button */}

      <div className="flex flex-1 justify-end">
        <img
          src="/assets/menu.png"
          alt="menu"
          className="cursor-pointer w-[40px] hover:shadow-lg hover:rounded-full"
          onClick={handleMenu}
        />
      </div>
    </div>
  );
};
