import * as React from "react";
import { Router, Switch, Route, Redirect } from "react-router";
import { history } from "./stores";

// Components
import { Home } from "./pages/home";
import { Press } from "./pages/press";
import { OurStory } from "./pages/our-story";
import { ProductAndServices } from "./pages/product-and-services";
import { RoadMap } from "./pages/road-map";
import { Web3Innovation } from "./pages/web3-inno";
import { SolutionShowcases } from "./pages/solution-showcases";
import { OurPartners } from "./pages/our-partners";
import { PrivacyPolicy } from "./pages/privacy-policy";

export default function Routers() {
  return (
    <Router history={history}>
      <Switch>
        <Route path={"/"} exact component={Home} />
        <Route path={"/press/"} exact component={Press} />
        <Route path={"/our-story/"} exact component={OurStory} />
        <Route path={"/roadmap/"} exact component={RoadMap} />
        <Route path={"/web3-innovation/"} exact component={Web3Innovation} />
        <Route
          path={"/solution-showcases/"}
          exact
          component={SolutionShowcases}
        />
        <Route
          path={"/product-and-services/"}
          exact
          component={ProductAndServices}
        />
        <Route path={"/our-partners/"} exact component={OurPartners} />
        <Route path={"/privacy-policy/"} exact component={PrivacyPolicy} />
        <Route path="*">
          <Redirect from={"/"} to={"/"} />
        </Route>
      </Switch>
    </Router>
  );
}
