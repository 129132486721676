import React from "react";
import { Divider } from "../";
import { MenuOption } from "./menu-option";
import { history } from "src/stores";

export const Menu = (props: any) => {
  const { handleMenu } = props;
  const privacyText = [{ text: "PRIVACY POLICY", link: "privacy-policy" }];

  return (
    <div className="flex flex-col py-[6vw] px-[8vw]  flex-1">
      <div className="flex flex-col flex-1">
        {/* menu option*/}
        <MenuOption handleMenu={handleMenu} />

        {/* devider */}
        <Divider className="flex  flex-1 " />

        {/* privacy */}

        {/* menu style */}
        <div className="flex flex-col md:flex-row justify-start flex-1">
          {privacyText.map((item, idx) => {
            const { text, link } = item;
            return (
              <div key={idx} className="cursor-pointer mr-[20px]">
                <span
                  className="text-[10px]"
                  onClick={() => history.push(link)}
                >
                  {text}
                </span>
              </div>
            );
          })}
        </div>
      </div>

      {/* pround */}
      <img
        className="absolute object-cover h-[40%] md:h-[50%] right-[0px] bottom-[20px] md:bottom-[10px]"
        src="/assets/pround_menu.png"
        alt="proud"
      />
    </div>
  );
};
