import React from "react";

export const Button = (props: any) => {
  const { text, onClick } = props;
  return (
    <div className="flex mr-5 my-2">
      <button
        className="bg-white rounded-full  cursor-pointer"
        onClick={onClick}
      >
        <p className="tracking-[.4em] font-semibold">{text}</p>
      </button>
    </div>
  );
};
