import React, { useState, useEffect } from "react";
import { Container } from "src/components";

function NumberCounter({ value }: any) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((c) => {
        const increment = Math.ceil(value / 100);
        if (c + increment >= value) {
          clearInterval(interval);
          return value;
        } else {
          return c + increment;
        }
      });
    }, 25);
    return () => clearInterval(interval);
  }, [value]);

  return <span>{count.toLocaleString() + "+"}</span>;
}

export const OurStory = (props: any) => {
  const storyItems = [
    { topic: "MERCHANT PARTNERS", mark: 5000 },
    { topic: "PARTNERED LOCATIONS", mark: 60000 },
    { topic: "USERS", mark: 1000000 },
    { topic: "LIFESTYLE COUPONS", mark: 500000 },
  ];

  return (
    <Container>
      <div className="flex pt-[20px]">
        {/* title */}
        <p className="text-3xl md:text-7xl font-black tracking-widest gradient-txt uppercase">
          OUR STORY
        </p>
      </div>

      {/* content */}
      <div className="flex flex-col pt-[20px]">
        <div className="flex flex-col gap-5 md:px-24 ">
          <div className="mt-5">
            <h2 className="uppercase font-[600] text-primary">
              Asia Top Loyalty Revolutionizes Rewards with Web3.0
            </h2>
          </div>
          <div className="flex flex-col gap-5">
            <p className={$textStyle}>
              Asiatop loyalty is a Web3.0 loyalty company that offers a
              cutting-edge platform empowered by AI. Our ecosystem, spanning
              over 50,000 merchant locations in GBA and Hong Kong, creates a
              universal loyalty point model that enables customers to earn and
              redeem loyalty points across multiple businesses, regions, and
              merchants.
            </p>
            <p className={$textStyle}>
              Our Metaverse solutions and gamification features offer
              personalized customer experiences that drive loyalty and revenue
              growth for businesses. Customers engage with games and earn
              virtual rewards, such as NFTs and tokens, while businesses capture
              the attention of the next generation of consumers through
              play-to-earn and buy-to-earn programs.
            </p>
            <p className={$textStyle}>
              Partnering with Asiatop loyalty enhances customer loyalty,
              increases revenue, and streamlines operations. Our innovative
              fintech solutions revolutionize loyalty programs, providing an
              unmatched platform in the market. Transform your loyalty program
              into a valuable and engaging experience for your customers with
              Asiatop loyalty in the Web3.0 era.
            </p>
          </div>
        </div>
      </div>

      {/* count */}
      <div className="flex flex-col lg:flex-row justify-around pt-[20px] my-[20px] flex-wrap">
        {storyItems.map((item, idx) => {
          const { topic, mark } = item;
          return (
            <div key={idx} className="flex flex-col items-center gap-3 m-5">
              <div>
                <p className="gradient-txt text-3xl sm:text-5xl font-black tracking-wider">
                  <NumberCounter value={mark} />
                </p>
              </div>
              <div>
                <p className="text-sm sm:text-base font-extrabold uppercase tracking-wider">
                  {topic}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

const $textStyle = `text-dark font-bold leading-relaxed tracking-wider`;
