import React from "react";

export const BNPL = (props: any) => {
  return (
    <div className="flex flex-col gap-5 px-10 md:px-20 pt-[20px] md:pt-[50px]">
      <h2 className="uppercase font-[600] text-primary">
        Buy-Now-Pay-Later 2.0
      </h2>
      <div>
        <p className={`${$textStyle} pt-[20px]`}>
          Introducing Buy Now Pay Later 2.0 - the upgraded version of a popular
          payment solution that is unique in the market, leveraging innovative
          technology. Customers can enjoy a flexible payment option, paying in
          instalments at a low fee, while merchants can upsell their products
          and increase revenue. By adopting Buy Now Pay Later 2.0, the
          innovative payment solution, our ecosystem of 5000+ merchants and 1m+
          customers can create a unique synergy that brings value to both
          merchant partners and customers.
        </p>
      </div>
    </div>
  );
};

const $textStyle = `text-dark font-bold leading-relaxed tracking-wider`;
