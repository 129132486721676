import React from "react";
import { Button, Container, ImageWall } from "src/components";
import { DownloadBtns } from "src/components/footer/downloadBtns";

export const Web3Innovation = (props: any) => {
  return (
    <Container>
      <div className="pt-[20px]">
        {/* title */}
        <p className="text-3xl md:text-7xl font-black tracking-widest gradient-txt uppercase">
          Web3 Innovation
        </p>
      </div>

      <div className="pt-[20px] max-w-[1000px]">
        <p>
          This tradable utility NFT offers personalized digital customer
          experiences while increasing connectivity and publicity for customers
          and merchants. Holders of this limited-edition collection enjoy
          exclusive features and merchant offers, making it a valuable addition
          to any portfolio in the NFT ecosystem.{" "}
        </p>
      </div>

      <div className="pt-[20px]">
        <ImageWall />
      </div>

      <div className="pt-[20px]">
        <h2 className="uppercase font-[600] text-primary">AT Birds</h2>
        <p className="pt-[20px] max-w-[1000px]">
          Representing Hong Kong's sparrow and mahjong culture, each AT Bird
          embodies the basic mahjong tiles of dots（筒子）, bamboo（索子）, and
          characters（萬子）, as well as the winds(東、南、西、北）and
          dragons(中、發、白）. Each bird carries its own symbolic meaning.
        </p>
        <div className="mt-[20px]">
          <Button
            text="Mint Now"
            onClick={() => window.open("https://mint.atrewards.space")}
          />
        </div>
      </div>

      <div className="pt-[20px]">
        <h2 className="uppercase font-[600] text-primary">
          AT Rewards APP <br />
          The Ultimate Loyalty Exchange platform with Merchants in HK and GBA
        </h2>
        <p className="pt-[20px] max-w-[1000px]">
          AT Rewards App is a consumer-focused platform that provides access to
          over <span className="gradient-txt font-[900]">2,000 merchants</span>{" "}
          in Hong Kong & Greater Bay Area, in categories such as dining, travel,
          amusement, entertainment, and staycation. Customers can{" "}
          <span className="gradient-txt font-[900]">redeem</span> loyalty
          rewards seamlessly across multiple merchants, providing flexibility
          and value. The Obsidian Membership NFT is one example of the app's
          innovative capabilities that enhance the overall customer experience
          by providing exclusive benefits and rewards to members.
        </p>
        <div className="mt-[20px]">
          <DownloadBtns />
        </div>
        <div className="mt-[20px] flex flex-col sm:flex-row gap-[20px]">
          <img src="/assets/mobile_P1.png" alt="img-1" className={$imgStyle} />
          <img src="/assets/mobile_P2.png" alt="img-2" className={$imgStyle} />
        </div>
      </div>
    </Container>
  );
};

const $imgStyle = "max-w-[100%] sm:max-w-[300px] object-fit";
