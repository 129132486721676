import React from "react";
import { Container } from "src/components";

export const PrivacyPolicy = (props: any) => {
  return (
    <Container>
      <div className="pt-[20px]">
        {/* title */}
        <p className="text-3xl md:text-7xl font-black tracking-widest gradient-txt uppercase">
          Privacy Policy
        </p>
      </div>

      <div className="max-w-[1200px] mx-auto">
        <div className="pt-[20px]">
          <p className={$titleStyle}>AT Rewards 條款及細則</p>
        </div>

        <p className="pt-[20px]">
          AT Rewards乃由Asia Top Loyalty
          Limited亞洲卓盛有限公司營運的會員獎賞計劃。AT
          Rewards根據本條款及細則管理其會員計劃的運作。通過其平台，AT
          Rewards為會員提供不同的優惠和服務，包括以AT
          Rewards積分兌換指定商品和服務、個人化的市場資訊和認可活動。AT
          Rewards會員可以獲得及/或兌換由AT Rewards或透過AT
          Rewards提供之各項優惠，惟須受本條款及條件細則及其他相關條款約束。
        </p>

        <p className="pt-[20px]">每位成為AT Rewards之會員即表示他/她已：</p>

        <ul className="pt-[10px] list-disc">
          <li className={$listStyle}>
            閱讀、明白並接受本文所載的所有條款及細則；
          </li>
          <li className={$listStyle}>
            Asia Top Loyalty
            Limited亞洲卓盛有限公司竭力按照規定的標準處理閣下的資料，其中包括個人資料的收集、使用、保留及查閱，保障閣下的私隱及確保閣下個人資料的安全，以遵守（尤其是及在適用情況下）香港法例第
            486
            章《個人資料（私隱）條例》。為本私隱聲明列明之目的使用或提供閣下的個人資料前，我們可能會因法例規定須要索取閣下的同意。在此情況下，我們只會在取得閣下的同意後，才會開始按指定的方式使用閣下的個人資料。
          </li>
          <li className={$listStyle}>
            接受AT
            Rewards可對本條款及細則於任何時候作出修訂、更改或補充，以滿足AT
            Rewards當時需要和發展及其活動。繼續參與會籍即表示接受該等修訂、更改或補充；
          </li>
          <li className={$listStyle}>
            承擔保持瞭解AT
            Rewards透過不同途徑所公佈的所有有關條款、條件及政策的責任。
          </li>
        </ul>

        <div className="pt-[20px]">
          <p className={$titleStyle}>1. 加入AT Rewards</p>
          <p className={$listStyle}>
            1.1 所有年滿十六歲或以上人士均可加入AT Rewards，惟因被法律禁止加入AT
            Rewards或須於法定監護人監管下方可加入的人士除外。
          </p>
          <p className={$listStyle}>
            1.2 個人可通過申請加入AT Rewards時有效的不同渠道申請加入AT Rewards
            。申請須經AT
            Rewards批核，其決定將為最終且絕對不受約束。在任何情況下，AT
            Rewards均不會提供申請不予受理的原因。若遞交的申請沒有提供所有需要的資料及個人資料，或在遞交的申請中提供虛假或不準確的資料，該申請將不被批核，及/或若會籍已獲批核，該會籍會被視為終止。AT
            Rewards有權向擁有該等被視為終止的會籍的有關人士扣回、追討及/或廢除要給予或已給予的優惠（如有）。
          </p>
        </div>

        <div className="pt-[20px]">
          <p className={$titleStyle}>2. 兌換AT Rewards積分和優惠</p>
          <p className={$listStyle}>
            2.1 AT Rewards積分為會員可使用的貨幣於AT
            Rewards兌換優惠。在任何情況下，AT Rewards積分不可兌換為現金。
            會員可根據AT
            Rewards應用程序的推送訊息或以任何形式的公佈，透過認可服務進行合資格的消費或活動以賺取AT
            Rewrads積分。兌換比例由AT
            Rewards全權釐訂及可不時進行調整，不同會籍等級或有不同兌換比例。
            除非另有正式公佈，兌換比例會於應用程式上公佈，而兌換的積分將自動存入會員的帳戶。
          </p>
          <p className={$listStyle}>
            2.2 所有AT
            Rewards積分的有效期將在被存入會員帳戶之時決定。儘管網站或透過其他材料發表有關AT
            Rewards積分之有效期的任何資料，會員應根據其會員帳戶中所顯示有關AT
            Rewards積分的有效期為準，並相應地安排兌換。
          </p>
          <p className={$listStyle}>
            2.3 會員可使用已存入其帳戶的AT Rewards積分，惟AT
            Rewards可隨後對帳戶作任何調整而不另行通知，以糾正任何不準確的存入紀錄。
          </p>
          <p className={$listStyle}>
            2.4 在下列情況下，AT Rewards可隨時從任何AT
            Rewards會員帳戶中扣除任何AT Rewards積分： <br />
            (a) AT Rewards或任何服務提供者錯誤計算AT Rewards會員應獲取的AT
            Rewards積分;
            <br />
            (b) AT Rewards會員取消，撤銷或退還任何相關合資格服務或合資格的消費;
            <br />
            (c) 在實際或涉嫌欺詐的情況下; 或 <br />
            (d) 任何AT Rewards合理地確保會員正當地獲取AT Rewards積分的情況下。
          </p>
          <p className={$listStyle}>
            2.5 如果AT Rewards會員已經兌換/轉移了不屬於該會員的AT
            Rewards積分，AT Rewards可： <br />
            (a) 向AT Rewards會員收取已兌換產品/服務建議零售價的價值; <br />
            (b) 按照現行兌換率向AT Rewards會員收取已轉移的AT Rewards積分的價值;
            或
            <br />
            (c) 任何綜合上述的處理方法
          </p>
        </div>

        <div className="pt-[20px]">
          <p className={$titleStyle}>3. 認可服務</p>
          <p className={$listStyle}>
            3.1 AT Rewards將列明由Asia Top Loyalty
            Limited亞洲卓盛有限公司所提供的認可活動的類別，就該等活動所花的費用（「合資格的消費」）受AT
            Rewards認可以兌換AT Rewards積分。
          </p>
          <p className={$listStyle}>
            3.2 合資格的消費僅包括有關Asia Top Loyalty
            Limited亞洲卓盛有限公司成功收到的實際金額。所有利息、罰款、補償或任何與認可活動的消費無關的，將不包括在內。
          </p>
        </div>

        <div className="pt-[20px]">
          <p className={$titleStyle}>4. AT Rewards積分不可轉移</p>
          <p className={$listStyle}>
            4.1 會員不可以轉移AT Rewards積分予其他會員。
          </p>
        </div>

        <div className="pt-[20px]">
          <p className={$titleStyle}>5. 取消會籍</p>
          <p className={$listStyle}>
            5.1 若AT
            Rewards認為適當，或有關會員作出以下行為或出現以下情況，其會籍將被取消：
            <br />
            – 作出不適當、欺詐、濫用或敵對的行為；
            <br />– 違反或觸犯適用於AT Rewards的條款及細則； <br />– 參與AT
            Rewards或使用AT
            Rewards的優惠時作出任何不當行為或違法行為，包括於登記、賺取AT
            Rewards積分或換領優惠時提供欺詐性的資料； <br />– 被宣布破產；{" "}
            <br />– 在沒有Asia Top Loyalty
            Limited亞洲卓盛有限公司明確准許下，從事或有意從事任何AT
            Rewards積分買賣計劃或買賣行為；或 <br />– 身故。 <br />
            所有未換取的AT Rewards積分及其他優惠會即時作廢，且不得恢復。AT
            Rewards對於因上述原因而帳戶被終止的會員將不承擔任何責任。
          </p>
          <p className={$listStyle}>
            5.2 一般而言，AT Rewards不接受任何已取消帳戶的前會員提出的會籍申請。
          </p>
        </div>

        <div className="pt-[20px]">
          <p className={$titleStyle}>6. 個人資料</p>
          <p className={$listStyle}>
            6.1 本節及Asia Top Loyalty Limited亞洲卓盛有限公司聲明，就AT
            Rewards收集、保留、處理或以任何方式處理個人資料有關的私隱事宜的責任及政策提供資料遵守（尤其是及在適用情況下）香港法例第
            486 章《個人資料（私隱）條例》，適用）。
          </p>
          <p className={$listStyle}>
            6.2
            會員准許服務公司存取儲存於其會員帳戶內的所有資料，以確保所有聯絡資料、鑑別資料及服務公司所提供的服務的有關資料為最新及準確。會員亦准許服務公司存取及操作其會員帳戶，以便處理一切有關AT
            Rewards積分兌換之事宜。
          </p>
          <p className={$listStyle}>
            6.3 會員同意（除非已另行通知AT Rewards）透過AT
            Rewards使用會員的聯絡資料及地理定位數據接收AT
            Rewards以任何形式發出的通知、通訊、市場營銷和其他性質的資訊，包括有關電訊、網絡服務、電訊設備、客戶處所設備、電腦周邊產品、配件和軟件、秘書服務、個人助理服務、資訊服務、遊戲、運動、音樂、美容產品、電子產品、科技、電子商務、雲端服務、手機付款、旅遊、銀行、金融、投資、娛樂、電視、交通、家居、時尚、餐飲、煙酒、保險、教育、健康及保健、社交網絡、媒體及高端消費類產品及服務的禮品、折扣、特別優惠、優惠及其他宣傳資訊，不論所述產品及服務由哪一方供應或提供。會員確認AT
            Rewards的商業夥伴（並因此所述產品及服務）或會不時變更，會員將不會就該等更改而收到個別通知，但會員可透過指定通訊方式向AT
            Rewards就該等資料作出查詢。
          </p>
          <p className={$listStyle}>
            6.4 AT Rewards於其營運期間，尤其當處理及管理會員帳戶內的AT
            Rewards積分時，會使用某些個人資料，並以有需要的範圍為限（例如：以驗證及審計），向有關商戶或服務公司披露某些個人資料。
          </p>
          <p className={$listStyle}>
            6.5 如cookies已用於收集有關AT
            Rewards網站和應用程式的訪客的資料，您的電腦或裝置上的cookies或類似追踪工具或將用於個人化您的用戶體驗及/或在多個網頁及/或互聯網時域上維持您的身份。我們的網站和應用程式的最初設置為接受cookies。您可以透過更改您的網絡或流動瀏覽器設置以選擇停用或刪除cookies歷史；然而，如果您這樣做，您可能會發現我們的網站及/或我們的應用程式上的某些功能無法正常運作。
          </p>
        </div>

        <div className="pt-[20px]">
          <p className={$titleStyle}>7. 其他原則</p>
          <p className={$listStyle}>
            7.1 AT
            Rewards的管理、運作和組成成分可在任何時候因任何原因變更，會員不會收到個別通知。
          </p>
          <p className={$listStyle}>
            7.2 AT
            Rewards可向現有會員作出三個月事先通知後結束。所有會員須於該結束前使用所有AT
            Rewards積分； AT Rewards結束當日，任何剩餘並未使用的AT
            Rewards積分會被視為作廢，AT
            Rewards就此將不向有關會員或任何其他人士承擔任何責任。
          </p>
          <p className={$listStyle}>
            7.3 Asia Top Loyalty
            Limited亞洲卓盛有限公司可於任何時候轉讓所有權及/或AT
            Rewards的營運予任何人士而不個別地向會員作出通知。在該轉讓期間，並為確保暢順過渡，閣下保留於AT
            Rewards的個人資料將受新擁有者及/或營運者的管理。
          </p>
          <p className={$listStyle}>
            7.4 AT
            Rewards的所有優惠、設施、特惠、獎勵及服務受可供應情況所影響，並或會隨時變更而不作另行通知。
          </p>
          <p className={$listStyle}>
            7.5 除非已獲AT Rewards明確批准，從AT Rewards所收到的AT
            Rewards積分或其他優惠不得用作銷售、交換其他物品或轉讓。任何未經批准的轉讓、銷售或物品交換將被作廢。Asia
            Top Loyalty
            Limited亞洲卓盛有限公司保留權利向涉及該等交易的會員及其他人士索取賠償。
          </p>
          <p className={$listStyle}>
            7.6 會員從AT
            Rewards所收到的優惠可包含由第三方供應的產品或服務。會員使用該等產品須自行承擔風險，Asia
            Top Loyalty
            Limited亞洲卓盛有限公司將不就有關使用者所蒙受的任何人身受傷或財產損害承擔任何法律責任。
          </p>
          <p className={$listStyle}>
            7.7 若從AT Rewards所收到的AT
            Rewards積分或其他優惠受入息稅或其他稅項責任所影響，有關會員須自行承擔繳付所有該等稅項，Asia
            Top Loyalty Limited亞洲卓盛有限公司將不就該等AT
            Rewards積分或優惠相關的任何稅項責任、稅務或其他費用承擔任何責任。
          </p>
          <p className={$listStyle}>
            7.8 就透過或由AT Rewards所提供的商品或服務的類型、質量或適用性，AT
            Rewards不作任何明示或暗示的保證或聲明。
          </p>
          <p className={$listStyle}>
            7.9 Asia Top Loyalty
            Limited亞洲卓盛有限公司不就以下情況或行為負責：(a)
            任何會籍申請、通訊、換領要求或處理優惠一般行政的遺失、誤導或接收上的延遲；(b)
            優惠換領被盜或未經授權的優惠換領；(c) 第三方的任何行為或遺漏；或 (d)
            有關優惠描述、AT Rewards積分資料及兌換率的發表上的任何錯誤。
          </p>
          <p className={$listStyle}>
            7.10 Asia Top Loyalty
            Limited亞洲卓盛有限公司有全權酌情權決定所有條款及細則（包括在此所載的條款及細則）及規則的闡釋，而其決定將為最終。若出現任何與AT
            Rewards有關的爭議，AT
            Rewards的決定將為最終，並俱約束力。如本條款及細則的中文版本與任何翻譯版本有任何歧義，一概以中文版本為凖。
          </p>
          <p className={$listStyle}>
            7.11 AT Rewards放棄追究違反AT
            Rewards的任何特定條款的行為，將不構成AT
            Rewards放棄追究任何先前或隨後違反同一條條款的行為。
          </p>
          <p className={$listStyle}>
            7.12 Asia Top Loyalty
            Limited亞洲卓盛有限公司將不就任何類型（不論是基於合約法、侵權法或其他AT
            Rewards所產生或以任何方式與之相關的理由）的任何直接、間接、特殊、懲戒性、懲罰性、附帶或引起的損失、損害、支出承擔任何法律責任，不論該等損失、損害、支出是否因疏忽或其他原因所導致，亦不論Asia
            Top Loyalty Limited亞洲卓盛有限公司對引致索償的情況是否有任何控制。
            為避免疑慮，本條款及細則並不免除因疏忽而導致的人身傷亡的法律責任。在法律容許的最大範圍內，法規或其他所明示或暗示的所有條款，均不適用。
            <br></br>
            AT
            Rewards會盡力確保商業夥伴提供的產品及服務獲得供應，但不會就商業夥伴未能提供該等產品及服務所引起的任何損失承擔責任。當會員使用商業夥伴提供的服務，該等服務的條款及細則將會適用，AT
            Rewards不就任何損失承擔責任。
          </p>
          <p className={$listStyle}>
            7.13 除服務公司外，任何其他人士無權於香港法例623章《合約
            (第三者權利) 條例》下，執行此條款及細則，以及其他適用於AT
            Rewards的條款，亦不能享有任何上述條款所授予的利益。
          </p>
          <p className={$listStyle}>
            7.14 此條款及所有適用於AT
            Rewards的條款及細則須受香港法律管轄。任何AT Rewards所引起或與AT
            Rewards有關的爭議將根據香港法律透過調解及/或仲裁私下予以解決。
          </p>
        </div>
      </div>
    </Container>
  );
};

const $titleStyle = "font-[900] text-primary";
const $listStyle = "pt-[10px] ml-[40px]";
