import React, { useState } from "react";
import { Container } from "src/components";
import { productAndServiceInstruction } from "src/sample/product-and-service";
// import { NavButton } from "./nav-button";
import { NavButton } from "src/components/nav-button";
import { Overall } from "./overall";
import { Loyalty } from "./loyalty";
import { BNPL } from "./bnpl";
import { Visa } from "./visa";

export const ProductAndServices = (props: any) => {
  const [currentFilter, setCurrentFilter] = useState(0);

  const handleActive = (newFilter: any) => {
    setCurrentFilter(newFilter);
  };

  const buttonItems = [
    { txt: "OVERALL" },
    { txt: "LOYALTY" },
    { txt: "BNPL" },
    { txt: "PRE-PAID CARD" },
  ];

  return (
    <Container>
      <div className="flex pt-[20px]">
        {/* title */}
        <p className="text-3xl md:text-7xl font-black tracking-widest gradient-txt uppercase">
          PRODUCT & SERVICES
        </p>
      </div>

      {/* button */}
      <div className="flex flex-row justify-start pt-[20px] flex-wrap">
        {buttonItems.map((item, idx) => {
          const { txt } = item;
          return (
            <NavButton
              key={idx}
              idx={idx}
              text={txt}
              handleActive={handleActive}
              currentFilter={currentFilter}
            />
          );
        })}
      </div>

      {/* content */}
      <div className="pt-[20px]">
        {currentFilter === 0 && (
          <Overall
            productAndServiceInstruction={productAndServiceInstruction}
          />
        )}

        {currentFilter === 1 && <Loyalty />}
        {currentFilter === 2 && <BNPL />}
        {currentFilter === 3 && <Visa />}
      </div>
    </Container>
  );
};
