import React from "react";
import { Button } from "../button";

export const DownloadBtns = (props: any) => {
  return (
    <div className="flex flex-col z-10 justify-start md:flex-row">
      <Button
        text="iOS"
        onClick={() =>
          window.open("https://apps.apple.com/au/app/at-rewards/id1530145967")
        }
      />
      <Button
        text="ANDROID"
        onClick={() =>
          window.open(
            "https://play.google.com/store/apps/details?id=com.asiatop.loyalty&hl=en_AU&gl=US"
          )
        }
      />
      <Button
        text="WEBAPP"
        onClick={() => window.open("https://atrewards.space")}
      />
    </div>
  );
};
