import React from "react";

export const Overall = (props: any) => {
  const { productAndServiceInstruction } = props;
  return (
    <div className="flex flex-col gap-5 px-10 md:px-20">
      <div>
        <p className="text-dark font-bold leading-relaxed tracking-wider">
          AsiaTop Loyalty offers a range of products and services to create a
          fully decentralized and democratized loyalty ecosystem that empowers
          customers and merchants to engage in a mutually beneficial
          relationship. Our platform includes the following features:
        </p>
      </div>
      <div className="md:px-20">
        <ol className="list-decimal">
          {/* items */}
          {productAndServiceInstruction.map((item: any, idx: any) => {
            const { title, context } = item;
            return (
              <li key={idx} className="py-3">
                <div className="text-gray-700">
                  <p className="tracking-wider font-bold leading-relaxed text-dark">
                    <span className="gradient-txt inline-block mr-1">
                      {title}:{" "}
                    </span>
                    {context}
                  </p>
                </div>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
};
