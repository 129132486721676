import React from "react";

export const ImageWall = (props: any) => {
  return (
    <div className="flex flex-row items-center space-x-[15px] w-[100%] overflow-hidden overflow-x-auto">
      <img src="/assets/0.png" alt="0" className={$imageStyle} />
      <img src="/assets/1.png" alt="1" className={$imageStyle} />
      <img src="/assets/2.png" alt="2" className={$imageStyle} />
      <img src="/assets/3.png" alt="3" className={$imageStyle} />
      <img src="/assets/4.png" alt="4" className={$imageStyle} />
      <img src="/assets/5.png" alt="5" className={$imageStyle} />
    </div>
  );
};

const $imageStyle = "h-[300px]";
