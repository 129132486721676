import React from "react";
import { Container } from "src/components";

export const RoadMap = (props: any) => {
  const isMobile = window?.screen?.width < 1024;

  return (
    <Container noPadding={true}>
      {isMobile ? (
        <img
          className="absolute left-0 top-[60px] w-full"
          src="/assets/roadmap_mobile.png"
          alt="roadmap-mobile"
        />
      ) : (
        <>
          <img
            className="absolute object-contain left-[0px] bottom-[0px] max-h-[100%]"
            src="/assets/website_roadmap.png"
            alt="roadmap"
          />
          <img
            className="absolute object-cover h-[40%] md:h-[50%] right-[0px] bottom-[0px]"
            src="/assets/pround_menu.png"
            alt="proud"
          />
        </>
      )}
    </Container>
  );
};
