import React from "react";

export const Loyalty = (props: any) => {
  return (
    <div className="flex flex-col gap-5 px-10 md:px-20 pt-[20px] md:pt-[50px]">
      <h2 className="uppercase font-[600] text-primary">
        Loyalty-as-a-service
      </h2>
      <div>
        <p className={`${$textStyle} pt-[20px]`}>
          Our SaaS platform software integrates various applications such as
          CRM, O2O marketing, analytics, loyalty and membership management, and
          voucher systems. It can be customized to meet specific business needs
          and deployed across corporate networks on a variety of cloud
          platforms.
        </p>
        <p className={`${$textStyle} pt-[20px]`}>
          Merchants have access to their own portal and merchant-side app. Over
          a thousand SMEs are using our L-a-a-S and have become part of our
          ecosystem.
        </p>
      </div>
    </div>
  );
};

const $textStyle = `text-dark font-bold leading-relaxed tracking-wider`;
