import React from "react";
import { Container, Footer } from "src/components";

export const Home = (props: any) => {
  return (
    <Container type="home">
      {/* bacground text asiatop */}

      <div className="flex flex-col items-center flex-1">
        <div>
          <p className="font-[900] tracking-[.2em] md:tracking-[.25em] text-[6vw] gradient-txt mt-[.6em]">
            ASIATOP LOYALTY
          </p>
        </div>
        <div>
          <p className=" text-[2vw] uppercase  md:tracking-[.2em] font-bold ">
            Pioneering a New Era of Web 3.0 Loyalty Ecosystem
          </p>
        </div>
      </div>
      {/* pround */}
      <div className="flex flex-row items-center justify-center">
        {/* home style */}
        <img
          className="absolute object-cover w-[70%] md:w-[40%] top-[35%] md:top-[40%] overflow-hidden"
          src="/assets/pround_home.png"
          alt="proud"
        />
      </div>

      {/* footer */}
      <Footer />
    </Container>
  );
};
