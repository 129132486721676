import React, { useState } from "react";
import { Header } from "../header";
import { Menu } from "../menu";
import { Modal } from "antd";
export const Container = (props: any) => {
  const { children, type, noPadding = false } = props;
  const [menuOpened, setMenuOpened] = useState(false);

  const handleMenu = () => {
    setMenuOpened(!menuOpened);
  };

  return !menuOpened ? (
    <div
      className="min-w-screen min-h-screen bg-cover p-[30px]  overflow-hidden"
      style={{ backgroundImage: 'url("/assets/bg.png")' }}
    >
      <div
        className={`${
          type === "home" ? "bg-white/60" : "overflow-y-auto bg-white/80"
        } w-full  rounded-[20px] backdrop-blur ${
          noPadding ? `p-[30px] pb-[0px]` : `p-[30px]`
        } flex flex-col`}
        style={{ height: "calc(100vh - 60px)" }}
      >
        <Header menuOpened={menuOpened} handleMenu={handleMenu} />

        {type !== "home" ? (
          <div className={noPadding ? "" : "p-[10px] md:p-[20px]"}>
            {children}
          </div>
        ) : (
          <>{children}</>
        )}
      </div>
    </div>
  ) : (
    <div
      className="min-w-screen min-h-screen bg-cover p-[30px] "
      style={{ backgroundImage: 'url("/assets/bg.png")' }}
    >
      <div
        className="overflow-y-auto w-full  rounded-[20px] backdrop-blur p-[30px] flex flex-col"
        style={{ height: "calc(100vh - 60px)" }}
      >
        <Modal
          visible={menuOpened}
          onCancel={() => handleMenu()}
          footer={null}
          width={"100%"}
          mask={false}
          closeIcon={<img src="../assets/menu_close.png" alt="Close" />}
          style={{
            borderRadius: "20px",
            overflow: "hidden",
            position: "absolute",
            top: "30px",
            bottom: "30px",
            left: "30px",
            maxWidth: "calc(100vw - 60px)",
          }}
        >
          <Menu handleMenu={handleMenu} />
        </Modal>
      </div>
    </div>
  );
};
