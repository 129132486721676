const pressPosts = [
  {
    img: "https://scontent.fmel3-1.fna.fbcdn.net/v/t39.30808-6/350473471_618590676897886_248419118986182497_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=106&ccb=1-7&_nc_sid=730e14&_nc_ohc=i06HZvdqhEMAX8tOBcR&_nc_ht=scontent.fmel3-1.fna&oh=00_AfAIqziEHm1ckR0TFnPo0ceGSAKbHvYhptbbIITyoPDjSQ&oe=6487163F",
    title: "AT BIRD 6月「著數鳥」✨優惠加碼!🎊",
    date: "01 June, 2023",
    abstract: `🔺最新優惠🔺
依家喺 AT REWARDS APP，原本價值𝐇𝐊$25 嘅 Pacific Coffee 現金卷* 只售𝐇𝐊$17.5！ 優惠低至7折！咁抵！唔好錯過啦，即上 AT REWARDS APP 搶購更多優惠！

*數量有限，售完即止`,
    url: "https://www.facebook.com/photo?fbid=758983912897772&set=a.528971242565708",
  },
  {
    img: "https://media.licdn.com/dms/image/D5622AQGmVFtHhUmp3w/feedshare-shrink_800/0/1681964965700?e=1689206400&v=beta&t=wwpvoQoGDlchsLiTItvQDnusw0xI-9REcgtl0aqqkVc",
    title: "AsiaTop x HKSTP",
    date: "May, 2023",
    abstract:
      "Thank you HKSTP - Hong Kong Science and Technology Parks Corporation for providing us with the opportunity to showcase our Web3 solution and plan through a video shooting. We are grateful for the support of Derrick Cao, the Business Development Representative of China Mobile Hong Kong, and Syndicate Capital Group, who helped to make this video shooting a success.",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7054672392945631232/",
  },
  {
    img: "https://media.licdn.com/dms/image/D4E22AQFp85EGBJxusw/feedshare-shrink_800/0/1681790962563?e=1689206400&v=beta&t=RCb1bbN8EljLsEd55PbRbOFVdQL2x84nmLccAfmCB1w",
    title: "AsiaTop x 2022 Deloitte Technology Fast and Rising Star Program",
    date: "May, 2023",
    abstract:
      "We're honored to be recognized as one of the 40 high-growth and future-star companies, a symbol of the high-speed growing and continuously innovative companies, in the 2022 Deloitte Technology Fast and Rising Star Program for the Greater Bay Area. This recognition reflects our team's dedication, hard work, and innovation in the loyalty and rewards industry. Thanks to Deloitte for providing a platform to showcase promising companies in the region. We're motivated to continue delivering cutting-edge loyalty solutions to our clients, driving sustainable growth. Thank you, Deloitte China, for this incredible honor!",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7053942567775662080/",
  },
];

export { pressPosts };
