import React from "react";
import { Container, Divider } from "src/components";
import { pressPosts } from "../../sample/press";
import { trimTxt } from "src/utils";

export const Press = (props: any) => {
  return (
    <Container>
      <div className="flex pt-[20px]">
        {/* title */}
        <p className="text-3xl md:text-7xl font-black tracking-widest gradient-txt">
          PRESS
        </p>
      </div>

      {/* press board */}
      <div className="px:16 lg:px-32">
        {pressPosts.map((item, idx) => {
          const { title, date, abstract, img, url } = item;
          return (
            <div key={idx}>
              <div className="flex flex-col lg:flex-row py-10">
                <div className="flex lg:w-1/3 shrink-0 justify-center">
                  <img
                    src={`${img}`}
                    alt={title}
                    className="rounded-[20px] max-h-[250px] object-cover w-full"
                  />
                </div>
                <div className="flex flex-col lg:pl-10 pt-5 lg:pt-0">
                  <p className="text-[#666] tracking-wider uppercase font-bold text-[10px]">
                    {date}
                  </p>
                  <p className="font-bold text-lg pt-[20px] pb-[10px]">
                    {trimTxt(title, 70)}
                  </p>
                  <pre className="text-dark font-bold text-[12px]">
                    {trimTxt(abstract, 500)}
                  </pre>
                  <div className="flex flex-1 pt-5">
                    <p
                      className="self-end gradient-txt cursor-pointer uppercase tracking-wider text-[12px] font-[800]"
                      onClick={() => url && window.open(url)}
                    >
                      Read More
                    </p>
                  </div>
                </div>
              </div>
              {idx !== pressPosts.length - 1 ? <Divider /> : ""}
            </div>
          );
        })}
      </div>
    </Container>
  );
};
