import React from "react";
import { history } from "src/stores";

export const MenuOption = (props: any) => {
  const { handleMenu } = props;
  /* menu options */
  const menuItems = [
    { item: "HOME", link: "/" },
    { item: "OUR STORY", link: "our-story" },
    { item: "ROADMAP", link: "roadmap" },
    { item: "PRODUCT & SERVICE", link: "product-and-services" },
    { item: "PARTNERS", link: "our-partners" },
    { item: "WEB3.0 INNOVATION", link: "web3-innovation" },
    { item: "SOLUTION SHOWCASES", link: "solution-showcases" },
    { item: "PRESS", link: "press" },
  ];
  return (
    <div className="flex flex-col">
      {/* menu */}
      {menuItems.map((option, idx) => {
        const { item, link = null } = option;
        return (
          <div key={idx} className="flex md:m-1" onClick={link && handleMenu}>
            <p
              className="text-[4vw] md:text-[2vw] tracking-wider font-black cursor-pointer"
              onClick={() => link && history.push(link)}
            >
              {item}
            </p>
          </div>
        );
      })}
    </div>
  );
};
