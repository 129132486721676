export const padZerosBehind = (amount: any, dp: number) => {
  return String(amount * 10 ** dp);
};

export function getQueryParam(queryString?: string) {
  const urlSearchParams = new URLSearchParams(queryString);
  const queryObj = Object.fromEntries(urlSearchParams.entries());
  return queryObj;
}

export const formatDollar = (amount: any, withDollarSign?: false) => {
  amount = parseFloat(amount);
  const formatter = new Intl.NumberFormat(
    "en-US",
    withDollarSign
      ? {
          style: "currency",
          currency: "USD",
        }
      : {}
  );
  return formatter.format(amount);
};

export const trimTxt = (txt: string, length: number) => {
  return txt.length < length ? `${txt}` : `${txt.substring(0, length)}...`;
};
