import React from "react";

export const SocialIcons = (props: any) => {
  const currentYear = new Date().getFullYear();
  const icons = [
    {
      icon: "facebook",
      link: "https://www.facebook.com/at.reward/",
    },
    {
      icon: "linkedin",
      link: "https://www.linkedin.com/company/asiatoployalty/",
    },
    { icon: "instagram", link: "https://www.instagram.com/at.rewards/" },
  ];
  return (
    <div className="flex flex-col-reverse md:flex-row space-x-3 flex-1 items-start md:items-center my-2 justify-end">
      <div className="pt-[10px] md:pt-[0px]">
        <span className="text-[10px] text-right mr-[10px] font-[600]">
          @Asiatop {currentYear}
        </span>
      </div>
      {/* Social icons */}
      <div className="grid grid-flow-col grid-rows-1 md:flex md:flex-row">
        {icons.map((item, idx) => {
          const { icon, link } = item;
          return (
            <div key={idx} className="mr-[20px]">
              <img
                src={`/assets/${icon}.png`}
                alt={icon}
                className="cursor-pointer "
                onClick={() => window.open(link)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
