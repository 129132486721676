import React from "react";

export const Visa = (props: any) => {
  return (
    <div className="flex flex-col gap-5 px-10 md:px-20 pt-[20px] md:pt-[50px]">
      <h2 className="uppercase font-[600] text-primary">
        Convert Your Rewards to Cash with Our Pre-paid Card
      </h2>
      <div>
        <p className={`${$textStyle} pt-[20px]`}>
          Our co-brand prepaid card lets you amplify your rewards, which will
          never expire and can be redeemed at all merchants that accept it.
          Enjoy exclusive offers across shopping malls for a hassle-free
          shopping experience. Maximize the value of your loyalty rewards with
          us.
        </p>
        <img
          src="/assets/visa.png"
          alt="visa"
          className="rounded-[10px] w-[400px] mt-[40px]"
        />
      </div>
    </div>
  );
};

const $textStyle = `text-dark font-bold leading-relaxed tracking-wider max-w-[800px]`;
