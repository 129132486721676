import React from "react";

export const NavButton = (props: any) => {
  const { text, idx, currentFilter, handleActive } = props;
  return (
    <div className="flex mr-5 my-2">
      <button
        onClick={() => handleActive(idx)}
        className={`${
          currentFilter === idx ? `bg-primary` : `bg-white`
        } rounded-full cursor-pointer w-[150px]`}
      >
        <p
          className={`${
            currentFilter === idx ? `text-white ` : ``
          } tracking-[2px] font-semibold`}
        >
          {text}
        </p>
      </button>
    </div>
  );
};
